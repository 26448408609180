@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Aristotelica&display=swap');

@font-face {
  font-family: 'Gotham-Book';
  src: url('../public/fonts/Gotham-Book.otf');
}

@font-face {
  font-family: 'Aristotelica';
  src: url('../public/fonts/Aristotelica Display Extralight.ttf');
}

@font-face {
  font-family: 'gothambold';
  src: local('gothambold-webfont'), url('./fonts/gothambold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'gothambold';
  src: local('gothambold-webfont'), url('./fonts/gothambold-webfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@layer base {
  html {
    font-family: 'Inter', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Inter', sans-serif;
  }
}

.react-datepicker__triangle {
  left: -50px !important;
}

/**common*/

.bg-dl-screen-lightblue {
  background-color: #e3eefa;
}
h2.common-title {
  font-weight: 700;
  font-size: 28px;
  font-family: Inter, sans-serif;
  color: #000;
}
.btn-primary {
  background: #005cb9;
  border-radius: 40px;
  width: 140px;
  font-weight: 400;
  font-size: 16px;
  font-family: Inter, sans-serif;
  height: 42px;
  color: #fff;
}
div#root {
  background: #e3eefa;
}
.header {
  padding: 25px 30px !important;
}
.after-login {
  background: #fff;
}
.profile-header figure {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  border: 4px solid #00b3ff;
}
.navlink-list a {
  color: #000;
}
.calender-time-box {
  border-left: 1px solid #000 !important;
}

/**top-serch-field*/
.docter-link-title {
  font-weight: 700;
  font-size: 30px;
  color: #000;
  font-family: gothambold;
  margin-bottom: 45px;
}
.docter-link-title b {
  color: #005cb9;
}
.form-field-box-main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  z-index: 9;
  position: relative;
}

.field-2.form-field-box,
.field-3.form-field-box,
.field-4.form-field-box {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -43px;
  box-shadow: none;
  background: #edfaff;
  border: 2px solid #005cb9;
  width: 250px;
  padding-left: 95px;
}
.field-3.form-field-box,
.field-4.form-field-box {
  width: 250px;
}
.field-1.form-field-box {
  z-index: 10;
}
.field-2.form-field-box {
  z-index: 9;
}
.field-2.form-field-box svg {
  display: none;
}
.field-3.form-field-box {
  z-index: 1;
}
.field-4.form-field-box {
  z-index: 0;
}
.form-field-box {
  background: #fff;
  box-shadow: 0 6px 8px rgb(0 0 0 / 25%);
  border-radius: 68px;
  padding: 18px 35px;
  height: 95px;
  width: 400px;
}
.form-field-box .shadow-sm,
.form-field-box input {
  border: 0 !important;
  background: 0 0;
  box-shadow: none;
  --tw-shadow: none;
  --tw-shadow-colored: none;
  padding: 0 0;
  margin-top: 0 !important;
}
.form-field-box label {
  font-weight: 700;
  font-size: 16px;
  font-family: Inter, sans-serif;
  color: #000;
}
/* .relative.mt-1.rounded-md.shadow-sm .pointer-events-none{display:none} */
.submit-btn {
  background: #005cb9;
  border-radius: 68px;
  height: 95px;
  width: 250px;
  margin-left: -43px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  padding-left: 25px;
}
.submit-btn {
  background: #005cb9;
  border-radius: 68px;
  height: 95px;
  width: 250px;
  margin-left: -43px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}
.submit-btn img {
  margin-right: 10px;
}
.error-msge {
  text-align: center;
}

/**top-serch-field*/

.docter-just-click {
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(60px);
  border-radius: 40px 40px 0 0;
  width: 1025px;
  margin: auto;
}
.docter-just-click figcaption h3 {
  font-weight: 400;
  font-size: 54px;
  color: #000;
  font-family: gothambold;
  line-height: 60px;
}
.docter-just-click figure {
  margin-top: -127px;
  padding-right: 55px;
}
.docter-just-click figcaption {
  width: 50%;
  padding: 55px 35px 0 55px;
}
.wrapper-box {
  width: 1306px;
  margin: auto;
}
.search-box-top-main {
  margin-bottom: 55px;
}
.docter-just-click:after {
  border: 6px solid #005cb9;
  position: absolute;
  content: '';
  bottom: 0;
  width: 1306px;
  margin: auto;
  border-radius: 18px;
}
.shape-one {
  position: absolute;
  bottom: 44px;
  left: 0;
}
.docter-just-click-main {
  position: relative;
}

/**featured-physicians*/
.featured-physicians-inner {
  background: #fff;
  border-radius: 40px;
  padding: 30px 30px;
}
.featured-physicians-inner figure span,
.direction-info-L figure span {
  margin: 7px;
  background: #edfaff;
  width: 70px;
  height: 70px;
  display: block;
  border-radius: 50%;
}
.featured-physicians-inner figure,
.direction-info-L figure {
  border: 4px solid #00b3ff;
  display: inline-flex;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}
.featured-physicians-inner {
  text-align: center;
}
.docter-info {
  background: #edfaff;
  border-radius: 40px;
  padding: 10px 28px;
  font-size: 15px;
  color: #000;
  font-weight: 400;
  display: block;
  width: 180px;
  margin: 20px auto 35px;
}
.bottom-link {
  display: block;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  text-decoration-line: underline;
  color: #000;
  margin-top: 30px;
}
.featured-physicians-inner figcaption h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000;
}
.btn-md {
  height: 60px;
  width: 180px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.light-bg {
  background: #00b3ff;
}
.btn-sm {
  width: 220px;
}
span.shape-two {
  position: absolute;
  right: 0;
  bottom: 75px;
}
.featured-physicians-main {
  position: relative;
}

/**slider*/

.slider-inner {
  display: flex;
  background: linear-gradient(270deg, #00b3ff 0, #005cb9 100%);
  border-radius: 40px 190px 190px 40px;
  border-top-right-radius: 190px;
  border-bottom-right-radius: 190px;
}
.slider-left {
  width: 55%;
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 60px;
  background: #fff;
  border-radius: 38px;
  overflow: hidden;
}
.slider-right {
  width: 45%;
  position: relative;
}
.slider-right figure {
  margin-top: -120px;
}
h2.slider-title {
  line-height: 38px;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  color: #005cb9;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.slider-inner button.control-arrow.control-next {
  background: #f5f5f5;
  border-radius: 40px 0 0 40px;
}
.slider-right figure img {
  margin: auto;
}
.slider-inner .carousel .slide {
  text-align: left;
}
/* ul.list-disc li{list-style:none;text-align:left} */
.slider-inner .carousel-slider {
  outline: 0;
  display: flex;
  position: absolute;
  width: 100%;
  margin-right: auto;
  top: 0;
  bottom: 0;
  overflow: visible !important;
}
/* ul.list-disc li{list-style:disc inside;font-weight:400;font-size:18px;line-height:30px;color:#000} */
ul.list-disc {
  padding-left: 20px;
}
.slider-inner .carousel .control-prev.control-arrow {
  left: -60px !important;
  background: #f5f5f5 !important;
  border-radius: 40px 0 0 40px;
  opacity: 1 !important;
  top: -88px !important;
  bottom: -88px !important;
  width: 35px;
}
.slider-inner .carousel .control-prev.control-arrow:before {
  border-right: 11px solid #d9d9d9 !important;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
}
.slider-inner .carousel .control-next.control-arrow {
  right: -60px !important;
  background: #f5f5f5 !important;
  border-radius: 0 40px 40px 0;
  opacity: 1 !important;
  top: -88px !important;
  bottom: -88px !important;
  width: 35px;
}
.slider-inner .carousel .control-next.control-arrow:before {
  border-left: 11px solid #d9d9d9 !important;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
}
span.shape-three {
  position: absolute;
  left: 18px;
  right: 0;
  bottom: 0;
}
/**ur-platforms*/

.our-plates-form-inner {
  background: #fff;
  height: 262px;
  border-radius: 130px;
  overflow: hidden;
  position: relative;
}
.our-plate-forms-L {
  background: linear-gradient(270deg, #00b3ff 0, #005cb9 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #fff;
  padding: 0 200px;
}
span.medi-bottal {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.our-plate-forms-L figcaption,
.our-plate-forms-R .btn-primary {
  z-index: 1;
}
.our-plate-forms-L figcaption h3 {
  font-size: 36px;
}
.common-title.blue-title.pb-5 {
  color: #005cb9;
}

/**list-of-specialties*/

.blue-box-number {
  font-size: 14px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.white-box-text {
  color: #000;
  font-size: 16px;
}
.add-btn {
  background: #005cb9;
}
.border-line {
  border-top-width: 2px;
}
.border-line {
  border-color: #005cb9;
}

/***footer****/

footer.bg-dark-blue {
  background: #005cb9;
}
.link-footer a {
  color: #fff;
}
.all-reserved {
  color: #fff;
}
.chat-info {
  background: #edfaff;
  border: 4px solid #00b3ff;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 80px;
  bottom: 40px;
  z-index: 99;
}
.chat-info svg {
  width: 58px;
}

/***docters-team****/

h1.docters-team-title {
  font-size: 40px;
  line-height: 43px;
  text-align: center;
  color: #fff;
  position: relative;
  font-weight: 700;
}
.docters-team-bg {
  background: #00b3ff;
  margin-bottom: 200px;
}
.docters-team-main-L {
  width: 50%;
  margin-bottom: -110px;
}
.docters-team-main-R {
  width: 50%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.docters-team-title-sub p {
  color: #fff;
}
.icon-box {
  background: #b7e9ff;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: auto;
}
.doc-link-box {
  overflow: hidden;
}
.docters-team-main-flex {
  align-items: flex-end;
}
.team-docter-title {
  color: #005cb9;
  font-weight: 400;
  padding: 18px 35px;
}
.consultar-link a {
  padding: 15px 35px 18px;
  display: block;
  color: #005cb9;
  font-weight: 600;
  font-size: 20px;
  background: #f5f5f5;
}

.consultar-link span {
  font-size: 34px;
  position: relative;
  top: 3px;
  line-height: 10px;
}

/***what is docter link ***/

.whta-is-docter-link-box-R {
  background: linear-gradient(270deg, #00b3ff 0, #005cb9 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  color: #fff;
  padding: 0 200px;
}
h2.docter-team-title {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  color: #005cb9;
  text-align: left;
  margin-bottom: 12px;
}
.docter-team-sub-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #000;
  text-align: left;
}
.what-is-docter-link-box-inner {
  background: #fff;
  border-radius: 40px;
}
.what-is-docter-link-box-R {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  background: linear-gradient(270deg, #00b3ff 0, #005cb9 100%);
  transform: rotate(-180deg);
  border-top-left-radius: 190px;
  border-bottom-left-radius: 190px;
}
.what-is-docter-link-box-R img {
  transform: rotate(-180deg);
}
.what-is-docter-link-box-inner {
  background: #fff;
  border-top-right-radius: 190px;
  border-bottom-right-radius: 190px;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}
.what-is-docter-link-box-R figure.img-box {
  margin-bottom: -137px;
  margin-right: -65px;
}
.what-is-docter-link-box-L {
  padding: 35px 60px 40px;
}
.what-is-our-goal {
  background: #fff;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  width: 50%;
  padding: 35px 250px 90px 60px;
  margin-left: auto;
  position: relative;
}
.shape-4 {
  position: absolute;
  right: 0;
  bottom: 15px;
}
.shape-5 {
  position: absolute;
  right: 125px;
  bottom: 170px;
}
.what-is-our-goal-box {
  position: relative;
}

/**who-we-are***/

.bg-blue-gredient {
  background: linear-gradient(180deg, #00b3ff 0, #005cb9 100%);
  border-radius: 40px;
}
.who-we-are-title {
  font-weight: 700;
  font-size: 32px;
  text-align: left;
  color: #fff;
  margin-bottom: 15px;
}
.who-we-are-sub-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}
.footer-text-who {
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  text-align: left;
  color: #fff;
}
.shape-6 {
  position: absolute;
  right: -30px;
  top: -26px;
}
.relative.who-we-are-L {
  margin-top: 30px;
}
.who-we-are-title.primary-color {
  color: #005cb9;
}
.border-t-2.pt-6.employee-info-box {
  border-color: #005cb9;
}
.employee-info-dt {
  font-weight: 400;
  font-size: 20px;
  color: #000;
}
dd.employee-info-dd {
  font-weight: 700;
  font-size: 32px;
  color: #005cb9;
}
.who-we-are-four-box-inner {
  background: #00b3ff;
  border-radius: 130px;
  padding: 25px 60px;
  text-align: right;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  min-height: 122px;
}
.who-we-are-four-box-inner h5 {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #fff;
  width: 60%;
  margin-left: auto;
  text-align: center;
}
.who-we-are-four-box-inner:after {
  background: #4fcaff;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: '';
  width: 40%;
  height: 100%;
}

/**profile-docter**/

.main-docter-profile {
  background: linear-gradient(270deg, #00b3ff 0, #005cb9 100%);
  border-radius: 40px;
  display: flex;
  padding-top: 150px;
  overflow: hidden;
}
.main-top-docter-profile {
  display: flex;
  background: #fff;
  width: 100%;
  padding: 0 60px;
}
figure.main-top-docter-profile-L {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #fff;
  margin-top: -85px;
}
figcaption.main-top-docter-profile-R {
  flex: 1 1;
  display: flex;
  padding: 30px 30px 35px 30px;
}
.name-of-docter h4 {
  font-weight: 700;
  font-size: 26px;
  color: #000;
}
.availability-info-main,
.name-of-docter {
  width: 50%;
}
ul.name-of-docter-list li {
  width: 190px;
  background: #edfaff;
  border-radius: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000;
  padding: 10px 11px;
  text-align: center;
  margin-right: 15px;
  margin-top: 15px;
}
li.cousult-from {
  background: #dcfff2 !important;
  font-weight: 700 !important;
}
ul.name-of-docter-list {
  display: flex;
  flex-wrap: wrap;
}
.availability-info-L {
  background: #edfaff;
  border-radius: 20px;
  padding: 30px 25px;
  display: flex;
  width: 50%;
}
.availability-info-main {
  display: flex;
  align-items: flex-start;
}
.availability-info-L figcaption h5 {
  font-weight: 700;
  font-size: 18px;
  color: #000;
}
.availability-info-L figcaption p {
  font-weight: 400;
  font-size: 16px;
  color: #000;
}
.availability-info-L figcaption {
  padding-left: 12px;
  text-align: center;
}
.comm-box-shadow {
  background: #fff;
}
.profile-top {
  display: flex;
  align-items: center;
  padding: 25px 40px;
  border-bottom: 1px solid #000;
}
.profile-top figure {
  display: flex;
  align-items: center;
  margin-right: 60px;
}
.profile-top figure h3 {
  font-weight: 700;
  font-size: 20px;
  color: #000;
  padding-left: 20px;
}
span.blue-tag {
  width: 190px;
  background: #edfaff;
  border-radius: 40px;
  font-weight: 400;
  font-size: 15px;
  color: #000;
  padding: 10px 25px;
  text-align: center;
}
.profile-body {
  justify-content: space-between;
  padding: 25px 40px;
}
.profile-body p {
  font-weight: 400;
  font-size: 18px;
  color: #000;
}
ul.edu-info li:after {
  background: #d9d9d9;
  width: 18px;
  height: 18px;
  position: absolute;
  left: 0;
  top: 3px;
  border-radius: 50%;
  content: '';
}
ul.edu-info li {
  position: relative;
  padding-bottom: 35px;
  padding-left: 35px;
}
ul.edu-info li:before {
  content: '';
  border-left: 1px solid #000;
  left: 8px;
  position: absolute;
  bottom: 0;
  height: 54%;
  top: 23px;
  margin: auto;
}
ul.edu-info li:last-child::before {
  border: 0;
}
.flex.profile-body-inner {
  justify-content: space-between;
}
ul.horarios-info li {
  position: relative;
  padding-left: 30px;
  padding-bottom: 15px;
}
ul.horarios-info li:after {
  background: #00b3ff;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 7px;
  content: '';
  border-radius: 50%;
}
.tablist button {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  padding: 0 26px 12px;
}
.tablist {
  border-bottom: 1px solid #000;
}
.tablist button.active {
  color: #00b3ff;
  border-bottom: 4px solid;
  margin-bottom: -1px;
  font-weight: 700;
}
.tablist button:focus-visible {
  outline: 0;
}
/**calender-box***/

.calender-box .pt-16 {
  padding-top: 0;
}
.calender-box .max-w-md {
  max-width: 100%;
}
.radio-coustom-design {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio-coustom-design p {
  font-size: 14px;
  font-weight: 600;
}
.radio-coustom-design input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radio-coustom-design .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border: 1px solid #a29898;
}
.radio-coustom-design input:checked ~ .checkmark {
  background-color: #fff;
  border: 1px solid #00b3ff;
}
.radio-coustom-design .checkmark:after {
  content: '';
  position: absolute;
}
.radio-coustom-design input:checked ~ .checkmark:after {
  display: block;
  background: #00b3ff;
}
.radio-coustom-design .checkmark:after {
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #a29898;
  right: 0;
  bottom: 0;
  margin: auto;
}
.common-box-white {
  background: #fff;
  border-radius: 14px;
  padding: 40px 45px;
  position: relative;
  border-left: 11px solid #00b3ff;
  align-items: flex-start;
  margin-bottom: 40px;
}
.name-docter-info-box-L {
  flex: 0 0 30%;
}
.name-docter-info-box-R {
  flex: 1;
  border-left: 1px solid #000;
  padding-left: 25px;
}
.name-docter-info-box-L figure {
  border: 4px solid #00b3ff;
  display: inline-flex;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}
.name-docter-info-box-L figure span {
  margin: 7px;
  background: #edfaff;
  width: 80px;
  height: 80px;
  display: block;
  border-radius: 50%;
}
.name-docter-info-box-L figcaption {
  margin-left: 40px;
  text-align: center;
}
.title-info {
  font-weight: 700;
  font-size: 20px;
  color: #000;
  margin-bottom: 16px;
}
.radio-text-list li p {
  font-weight: 400;
  font-size: 18px;
  color: #000;
}
.radio-text-list li {
  margin-bottom: 13px;
}
.calender-title {
  font-weight: 700;
  font-size: 20px;
  color: #000;
  margin-bottom: 40px;
}
.information-box-info-R {
  width: 20%;
}
.information-box-info-L {
  flex: 1;
  padding-right: 45px;
} /*border-right:1px solid #000; */
.information-box-info.common-box-white {
  align-items: center;
  border-top-right-radius: 133px;
  border-bottom-right-radius: 133px;
}
.information-box-info-L textarea.form-control {
  border: 1px solid #000;
  border-radius: 10px;
  width: 100%;
  height: 90px;
  resize: none;
  font-weight: 400;
  font-size: 14px;
}
span.required {
  font-weight: 400;
  font-size: 14px;
  color: #005cb9;
}
.information-box-info-R {
  width: 18%;
  text-align: center;
}

.calender-show-date {
  display: flex;
  align-items: center;
}
.calender-show-date figcaption h4 {
  font-weight: 700;
  font-size: 24px;
  color: #000;
  margin-bottom: 4px;
}
.calender-show-date figcaption h2 {
  color: #005cb9;
  font-size: 32px;
  font-weight: 700;
}
.calender-show-date figcaption {
  padding-left: 25px;
}
.name-docter-info-box-C {
  flex: 1;
}
.name-docter-info-box-C {
  flex: 1;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  padding-left: 40px;
  margin-left: 40px;
}

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-content {
  background-color: #fff;
  margin: 15% auto;
  padding: 80px 30px 120px;
  border: 0 solid #888;
  width: 1016px;
  border-radius: 40px;
}
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: 700;
}
.top-img-box h3 {
  font-weight: 700;
  font-size: 40px;
  color: #005cb9;
  line-height: 49px;
}
.top-img-box figure svg {
  margin: auto;
  margin-bottom: 20px;
}
.modal-btn-group.flex {
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.modal-btn-group span.blue-tag {
  width: auto;
  margin-right: 25px;
  font-size: 16px;
  color: #005cb9;
  padding: 10px 36px;
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.green-info-box {
  background: #dcfce7;
  border-radius: 14px;
  border-left: 8px solid #22c55e;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.green-info-box h5 {
  font-weight: 400;
  font-size: 20px;
  color: #3f9772;
  margin-bottom: 5px;
}
.green-info-box p {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #3f9772;
}
.direction-info {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}
.direction-info-L {
  flex: 0 0 50%;
  border-right: 1px solid #000;
}
.direction-info-R {
  flex: 1;
  padding-left: 50px;
}
.appoinment-successfully-inner.text-center {
  width: 60%;
  margin: auto;
}
.top-img-box.text-center {
  margin-bottom: 30px;
}
.direction-info-L figcaption h4 {
  font-weight: 400;
  font-size: 20px;
  color: #000;
  margin-bottom: 15px;
}
.direction-info-R figure {
  display: flex;
  align-items: center;
}
.direction-info-R figure h3 {
  padding-left: 20px;
  font-weight: 400;
  font-size: 20px;
  color: #000;
}
.direction-info-R p {
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  color: #000;
  margin-top: 15px;
}
time.agenda-time {
  background: #005cb9;
  border-radius: 14px;
  font-weight: 700;
  font-size: 30px;
  text-align: center;
  color: #fff;
  padding: 4px 17px;
  margin-right: 20px;
}
.agenda-text-heading {
  font-weight: 400;
  font-size: 20px;
  color: #000;
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.no-time-show {
  font-weight: 400;
  font-size: 20px;
  color: #000;
  margin-top: 20px;
  margin-bottom: 15px;
}
.time-slot-list li {
  background: #edfaff;
  border-radius: 40px;
  width: 148px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  margin-top: 20px;
}
.time-slot-list {
  display: flex;
  flex-wrap: wrap;
}
.time-slot-list li.active {
  background: #005cb9;
  color: #fff;
  font-weight: 600;
}
.calender-indicate-info ul li {
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;
  margin-top: 10px;
  position: relative;
  padding-left: 47px;
}
.calender-indicate-info li:after {
  background: #00b3ff;
  width: 30px;
  height: 11px;
  content: '';
  border-radius: 30px;
  position: absolute;
  left: 0;
}
.calender-indicate-info li.red-info:after {
  background: red;
}
.calender-indicate-info {
  margin-left: 30px;
  margin-top: 20px;
}
.calender-box .bg-dl-red {
  background: #00b3ff;
}
.week-name-list div {
  color: #333;
  font-weight: 700;
}
.calender-time-box {
  border-color: #000;
}
.flex.items-center.calender-top-head {
  padding: 0 32px;
}
.calender-top-head svg {
  color: #000;
}
button.btn.btn-primary.grey-btn {
  background: #a29898;
}

/* custom css */
figure.doctor__profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #fff;
  position: relative;
}
figure.doctor__profile > input {
  position: absolute;
  z-index: 2;
}
figure.doctor__profile > .hover_icon {
  position: absolute;
  z-index: 1;
  background-color: #0000008c;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
figure.doctor__profile:hover > .hover_icon {
  opacity: 1;
}

figure.doctor__profile > .hover_icon svg {
  width: 30px;
  height: 30px;
}
figure.doctor__profile > .hover_icon svg path {
  fill: #fff;
}

/***/

.form-heading-top {
  background: linear-gradient(270deg, #00b3ff 0, #005cb9 100%);
  text-align: center;
  padding: 17px 10px;
}

.form-heading-top h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 18.4758px;
  line-height: 22px;
  color: #ffffff;
}

.btn-out-line {
  background: #fff;
  border-radius: 40px;
  font-weight: 500;
  font-size: 16px;
  font-family: Inter, sans-serif;
  height: 42px;
  /* color: #005CB9; */
  border: 1.23172px solid #005cb9;
}
.citas-btn-out-line {
  background: #fff;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  font-family: Inter, sans-serif;
  height: 50px;
  /* color: #005CB9; */
  border: 2px solid #005cb9;
}
.side-bar-right {
  padding: 50px 40px !important;
}

/***/

.btn-sidebar .btn svg {
  display: none;
}

.btn-sidebar .btn {
  margin-bottom: 20px;
}

.bg-white.calender-box {
  padding: 45px 80px;
}

ul.time-slot-name-text li {
  background: #fff;
  color: #005cb9;
  border-radius: 33px;
  padding: 10px 19px;
  text-align: center;
  width: 38%;
  margin: 0 24px 17px;
}

ul.time-slot-name-text {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}

ul.time-slot-name-text li span {
  font-size: 14px;
  line-height: 11px;
  color: #b1b1b1;
}

ul.time-slot-name-text li p {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  /* color: #B1B1B1; */
}

ul.time-slot-name-text li.active {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #005cb9;
  color: #fff;
}

ul.time-slot-name-text li.active span,
ul.time-slot-name-text li.active p {
  color: #fff;
}

.calender-box .calender-title {
  margin-bottom: 0;
}

figcaption.information-box-info-left {
  text-align: left !important;
}

.appoinment-bottom-text {
  font-weight: 700;
  font-size: 16px;
  line-height: 13px;
  text-align: center;
  color: #000000;
}

.bg-white.calender-box.box-radious {
  border-radius: 22px;
}

.calender-show-date h5 {
  font-size: 18px;
  color: #005cb9;
}

.history-name h4 {
  font-size: 20px;
  line-height: 22px;
  color: #005cb9;
  margin-bottom: 5px;
}

.history-name p {
  font-size: 17px;
}

/* select {
        background-color: #FFFFFF;
        border-radius: 7.14058px;
        border: 0;
        font-size: 14px;
        height: 45px;
    } */

.form-control.min-w {
  width: 280px;
}

.form-label {
  font-size: 15px;
  color: #000000;
}

.search-box-inner {
  position: relative;
}

.search-box-inner input.form-control {
  border: 0.510041px solid #cacaca;
  border-radius: 33px;
  height: 45px;
  padding-left: 45px;
  width: 100%;
  color: #000;
}

.search-box-inner svg {
  fill: #005cb9;
  width: 30px;
}

.table-gredient thead {
  background: linear-gradient(270deg, #00b3ff 0%, #005cb9 100%);
}

.table-gredient thead th {
  background: none;
}

.table-gredient thead th {
  background: none;
  padding: 18px 35px;
  text-align: center;
  color: #fff;
}

.table-top {
  border-radius: 25px !important;
}

button.btn-table-1,
.btn-table-3 {
  border: 0.510041px solid #00b3ff;
  height: 36px;
  padding: 10px 20px;
  line-height: 11px;
  border-radius: 10px;
  color: #005cb9;
  font-size: 14px;
  margin-left: 20px;
  min-width: 160px;
}

.btn-table-3 {
  border-color: #cacaca;
  color: #000000;
  font-weight: 600;
}

.profile-top-content h4 {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
}

.profile-top-content p {
  font-size: 15px;
  color: #000000;
}

.tabs-heading-title {
  color: #000000;
  font-weight: 500;
  padding: 18px 35px;
}

.form-label-box label {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  /* margin-bottom: 10px; */
}

.tabs-heading-title select {
  border-radius: 33px !important;
  background-size: 31px;
}

.tabs-heading-title textarea {
  border-radius: 33px !important;
}

.docter-profile {
  background: linear-gradient(270deg, #00b3ff 0, #005cb9 100%);
  overflow: hidden;
  border-radius: 24px;
}

.docter-profile-L {
  margin-top: -80px;
}

.border-radius-24 {
  border-radius: 24px;
  overflow: hidden;
}

.color-custom {
  color: #005cb9;
}
.custome-bg {
  background: #005cb9;
}

.contact-form input::-webkit-input-placeholder {
  /* Edge */
  color: #005cb9;
}

.contact-form input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #005cb9;
}

.contact-form input::placeholder {
  color: #005cb9;
}

.whatsapp-number figcaption {
  margin-left: -24px;
}

.chain-list li {
  position: relative;
}

.chain-list li:after {
  background: #d9d9d9;
  width: 15px;
  height: 15px;
  position: absolute;
  left: 0;
  top: 4px;
  bottom: 0;
  content: '';
  border-radius: 50%;
}
.chain-list li:before {
  border-left: 1px solid #000;
  position: absolute;
  left: 8px;
  top: 23px;
  bottom: 0;
  content: '';
  height: 60%;
  margin: auto;
}
.chain-list li:last-child:before {
  border: 0;
}

.bullet-list-box li {
  position: relative;
  padding-left: 32px;
}

dl.bullet-list-box li:after {
  background: #38bdf8;
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  top: 7px;
  bottom: 0;
  content: '';
  border-radius: 50%;
}

.border-r-40 {
  border-radius: 40px !important;
  overflow: hidden;
}

.box-l {
  padding-right: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.box-r {
  padding-left: 20px;
}
.bg-cyan-50 {
  background: #edfaff;
}

.text-blue {
  color: #00b3ff;
}

.hover\:text-gray-600:hover .hover-content-box {
  display: block !important;
}

.bg-pinq {
  background: #ffc6e8;
}

/* .featured-physicians-inner .btn-primary {  transition: 0.6s;} */

/* .featured-physicians-inner:hover .btn-primary {
        transform: scale(1.2);
        transition: 0.6s;
    } */

.slider-main {
  margin-top: -32px;
}

textarea#message::-webkit-input-placeholder {
  /* Edge */
  color: #000;
}

textarea#message:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

textarea#message::placeholder {
  color: #000;
}

.bg-blue {
  background: #005cb9;
}

.light-bg-blue {
  background: #00b3ff;
}

.light-bg-sky {
  background: #edfaff;
  width: 42%;
  margin: auto;
}

.light-blue {
  background: #edfaff;
}
.text-light {
  color: rgba(0, 0, 0, 0.5);
}

.border-blue {
  border-color: #005cb9;
}

.border-light-blue {
  border-color: #00b3ff;
}

.boxfilter-list-comm {
  position: relative;
}

.boxfilter-list-comm:after {
  border-right: 1px solid #000;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 85px;
  content: '';
  margin: auto;
}

.filters-list-show-box-inner {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.contact-form input::placeholder {
  opacity: 0.6;
}
.contact-form textarea::placeholder {
  opacity: 0.4;
}
.react-datepicker {
  border: 14px solid #005cb9 !important;
  border-radius: 0 !important;
}
.react-datepicker__month-container {
  width: 100%;
}
.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
  width: 70px !important;
}
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  display: none;
}
.signup_datepicker .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  display: block;
}
.signup_datepicker .react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  width: 90%;
  margin-left: 13px;
  position: absolute;
  top: 3px;
  background: white;
}

.signup_datepicker .react-datepicker__navigation--next {
  right: -2px !important;
  margin-top: 5px;
}
.signup_datepicker .react-datepicker__navigation--previous {
  left: -2px !important;
  margin-top: 5px;
}

.react-datepicker__header {
  background-color: #fff !important;
}

.react-datepicker__current-month.react-datepicker__current-month--hasMonthDropdown {
  margin-bottom: 10px;
  text-transform: capitalize;
}
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  display: none !important;
}
.react-datepicker__day--keyboard-selected {
  background: #000 !important;
}
.react-datepicker__day--keyboard-selected {
  background: #000 !important;
}
.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
  width: 32px !important;
  height: 31px;
  align-items: center;
  justify-content: center;
  line-height: 30px !important;
  border-radius: 50% !important;
  text-transform: capitalize;
  font-size: 12px;
}
.react-datepicker__navigation-icon {
  position: relative;
  top: 5px;
  font-size: 20px;
  width: 0;
}
.react-datepicker__navigation {
  top: 8px !important;
}
.react-datepicker__day-names {
  margin-bottom: -8px;
  border-bottom: 1px solid #000;
}
.react-datepicker__day-names {
  margin: 0 8px;
}
.react-datepicker__header {
  border-bottom: 0 !important;
  border-top-left-radius: 0.3rem;
  padding: 8px 0 0;
  position: relative;
}
.react-datepicker__month {
  margin-top: 0;
}
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before,
.react-datepicker__year-read-view--down-arrow {
  border-color: rgb(0 0 0 / 54%) !important;
  border-style: solid;
  border-width: 2px 2px 0 0 !important;
}
.field-3 .react-datepicker-popper {
  left: -126px !important;
}

textarea#message::-webkit-input-placeholder {
  /* Edge */
  color: #000;
}

textarea#message:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000;
}

textarea#message::placeholder {
  color: #000;
}

.react-datepicker-popper[data-placement^='bottom'] {
  padding-top: 30px !important;
}

ul.absolute.z-10.mt-1.max-h-60.w-full.overflow-auto {
  width: 100%;
  max-width: 200px !important;
}

.flex.items-center.max-w-full.bg-white.border-l-8.border-sky-500.py-8.px-8.rounded-l-md.rounded-r-full {
  margin-top: 30px;
}
.pt-16.mb-20 {
  margin-bottom: 0;
}

.table-top .text-gray-500 {
  position: relative;
}

ul.filters-box-list {
  margin-bottom: 20px;
}

.icon-box svg {
  transform: scale(1.4);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  color: #fff !important;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .btn-box.pr-6.pl-12 {
    padding: 0 10px;
  }
}

.col-span-12.md\:col-span-8 svg.h-5.w-5.text-gray-400 {
  display: none;
}

.side-bar-right form {
  margin-bottom: 30px;
}
thead.bg-gradient-to-r.from-blue-700.to-blue-400 {
  background: linear-gradient(270deg, #00b3ff 0%, #005cb9 100%);
}

.text-center.w-2\/3.bg-green-100.border-green-500.border-l-8.py-5.rounded-lg.mb-10 {
  margin-top: 34px;
}

.green-info-box.w-1\/2 {
  margin-top: 40px;
}

.aristotelicaFont {
  font-family: 'aristotelica';
  margin-top: 3rem;
  font-weight: 500;
  font-size: 1.25rem;
}

.h-\[calc\(100vh_-_306px\)\].flex.bg-dl-screen-gray {
  height: auto;
  min-height: 85vh;
}

table.min-w-full.divide-y.divide-gray-300 tr th {
  text-align: center;
}

table.min-w-full.divide-y.divide-gray-300 tr td {
  text-align: center;
}

td.whitespace-nowrap.px-3.py-4.text-sm.text-black.truncate.max-w-xs.inline-flex {
  justify-content: center;
  margin: auto;
  display: block;
  text-align: center;
}
td.whitespace-nowrap.px-3.py-4.text-sm.text-black.truncate.w-\[200px\].inline-flex {
  margin: auto;
  display: block;
}
span.truncate.light-bg-sky.py-2.px-8.text-black.font-normal.rounded-full.w-32.block.text-center {
  margin: auto;
}

.col-start-4.col-end-7 .react-datepicker {
  border: 1px solid #ddd !important;
}

@media (max-width: 1199px) {
  .wrapper-box {
    width: 85%;
    margin: auto;
  }
  .featured-physicians-main .grid-cols-4 {
    width: 100%;
    grid-template-columns: initial;
    display: flex;
    flex-wrap: wrap;
  }
  .featured-physicians-main .grid-cols-4 {
    width: 100%;
    grid-template-columns: initial;
  }
  .featured-physicians-inner {
    text-align: center;
    width: 48%;
  }
  .docter-just-click:after {
    width: 85%;
  }
  .docter-just-click {
    width: 100%;
  }
  .docter-just-click figcaption h3 {
    font-weight: 400;
    font-size: 41px;
    color: #000;
    font-family: gothambold;
    line-height: 50px;
  }
  .carousel-root {
    width: 100%;
  }
  h2.slider-title {
    font-size: 24px;
    margin-bottom: 12px;
  }
  blockquote.slider-content-box li {
    font-size: 13px;
  }
  .bg-dl-screen-lightblue.pb-12.pt-20 {
    padding-top: 10px;
  }
  .docters-team-main-L .lg\:gap-y-0.gap-12 {
    display: flex;
  }
  .doc-link-box {
    border-radius: 48px;
    width: 50%;
  }
  .docters-team-main-L .lg\:gap-y-0.gap-12 {
    display: flex;
    gap: 24px;
  }
  .consultar-link a {
    font-size: 16px;
  }
  .team-docter-title {
    color: #005cb9;
    font-weight: 400;
    padding: 18px 35px;
    min-height: 90px !important;
  }
  h1.docters-team-title {
    font-size: 36px;
  }
  .docters-team-bg {
    background: #00b3ff;
    margin-bottom: 150px;
  }
  .consultar-link {
    margin-top: 20px;
  }
  .bg-dl-screen-lightblue.pt-12 {
    padding-top: 5px;
  }
  .slider-left {
    border-radius: 26px;
  }

  .whta-is-docter-link-box-main {
    padding-top: 70px;
  }
  .whta-is-docter-link-bottom {
    padding-top: 60px;
  }
  .what-is-our-goal {
    width: 70%;
  }
  .whta-is-docter-link-box-main {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .docter-team-sub-title {
    font-size: 16px;
  }
  h2.docter-team-title {
    font-size: 26px;
    margin-bottom: 4px;
  }
  .whta-is-docter-link-box {
    margin-bottom: 55px;
  }
  .who-we-are-four-box {
    padding-bottom: 100px;
  }
  .who-we-are-title {
    font-size: 22px;
    margin-bottom: 0;
  }
  .who-we-are-R {
    margin-top: 30px;
  }
  .who-we-are-four-box-inner h5 {
    font-size: 20px;
  }
  .who-we-are-four-box.pt-20.pb-60 {
    padding-top: 20px;
    padding-bottom: 75px;
  }
  dd.employee-info-dd {
    font-size: 26px;
  }
  .who-we-are.pt-20 {
    padding-top: 45px;
  }
  .who-we-are-four-box-inner {
    min-height: 108px;
  }

  .relative.bg-white.py-16.sm\:py-24 {
    padding-top: 20px;
  }
  h2.text-3xl.text-gray-900.font-extrabold.tracking-tight.sm\:text-4xl {
    font-size: 26px;
  }

  .bg-white.rounded-lg.px-20.pt-12.pb-10 {
    width: 90%;
    margin: auto;
  }
  .min-h-full.max-w-7xl.mx-auto.mt-10 {
    max-width: 95%;
  }
  img.h-40.w-40.rounded-full.group-hover\:opacity-75.border-4.border-white {
    width: 130px;
    height: 130px;
  }
  .min-w-0.flex-1.px-10.py-7.docter-profile-R.flex {
    padding-left: 10px;
    padding-right: 0;
    flex-wrap: wrap;
    align-items: center;
  }
  .pr-5.flex-1 {
    padding-right: 0;
  }
  .appointment p.text-md {
    font-size: 14px;
  }
  .appointment figure svg {
    width: 35px;
  }
  p.mt-2.flex.items-center.text-sm.mr-5.py-3.px-8.text-black.docter-tag.bg-sky-50.rounded-full {
    margin-right: 15px;
  }
  .appointment figure svg {
    width: 23px;
  }
  .appointment p.text-md {
    font-size: 12px;
  }
  h4.text-md.text-black.font-bold.mb-1 {
    font-size: 14px;
  }
  .appointment.bg-sky-50.rounded-xl.py-4.px-7.flex.items-center.mr-8 {
    padding: 25px 20px;
    margin-right: 15px;
  }
  p.truncate.text-2xl.text-black.font-bold.mb-3 {
    font-size: 20px;
    margin-bottom: 3px;
  }
  button.custome-bg.inline-flex {
    padding: 10px 13px;
    font-size: 14px;
    margin-top: 20px;
  }
  .tablist {
    margin-top: 10px;
  }
  .tablist button {
    font-size: 14px;
    padding: 0 20px 12px;
  }
  section.my-6 h2 {
    font-size: 15px;
  }
  section.my-6 svg {
    width: 28px;
  }
  .inner-content {
    font-size: 14px;
  }
  .chain-list li {
    font-size: 14px;
  }
  .docter-profile {
    padding-top: 110px;
  }
  .chain-list li {
    font-size: 14px;
    padding-bottom: 20px;
  }
  .lg\:grid-cols-12 {
    grid-template-columns: 100% !important;
    display: flex !important;
  }
  aside.lg\:col-span-2.bg-white.side-bar-profile {
    width: 30%;
  }
  .tablist {
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .space-y-6.sm\:px-6.lg\:px-0.lg\:col-span-10.side-bar-right.bg-dl-screen-lightblue {
    width: 70%;
  }
  nav.space-y-1.ml-3.mt-5 {
    margin: 0;
  }
  .rounded-full.border.border-gray-100.bg-white.p-3.shadow-md.w-1\/3 {
    width: 55%;
  }

  .calender-show-date figcaption h4 {
    font-size: 20px;
  }
  .calender-show-date figcaption h2 {
    font-size: 22px;
  }

  .calender-show-date figure svg {
    width: 50px;
  }

  .filters-info.flex.items-center.boxfilter-list-comm:first-child {
    flex: initial;
    width: 100%;
    margin-bottom: 15px;
    padding: 0;
  }

  .filters-info:first-child:after {
    border: 0;
  }
  .availability-box.light-blue {
    padding: 25px 0;
  }
  .filters-list-show-box-inner {
    flex-wrap: wrap;
  }
  .availability-box h5 {
    font-size: 18px;
  }
  .availability-box.light-blue {
    padding: 15px 0;
  }
  .flex.docter-profile-R-inner {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .tabs-heading-title .h-20.flex {
    flex-wrap: wrap;
  }
  .tabs-heading-title .py-2.px-4.\!w-auto.flex.justify-between.gap-2 {
    margin-bottom: 15px;
  }
  .grid.grid-cols-6.gap-4 .col-span-6.sm\:col-span-3 {
    grid-column: span 8;
  }

  .grid.grid-cols-6.gap-6 .col-span-6.sm\:col-span-3 {
    grid-column: span 8;
  }

  .grid.grid-cols-6.gap-6 .col-span-6 {
    grid-column: span 8;
  }

  .grid.grid-cols-6.gap-6 .col-start-1.col-end-5 {
    grid-column-end: 8;
  }

  .grid.grid-cols-6.gap-4 .col-start-1.col-end-4 {
    grid-column-end: 8;
  }

  .space-y-6.sm\:px-6.lg\:px-0.lg\:col-span-10.side-bar-right.bg-dl-screen-lightblue.create-appoinment {
    width: 100%;
  }

  .create-appoinment .md\:pr-14.pl-20 {
    padding-left: 0;
  }

  ul.time-slot-name-text li {
    width: 100%;
  }

  .information-box-info.common-box-white {
    flex-wrap: wrap;
  }

  .information-box-info .name-docter-info-box-C {
    flex: initial;
    width: 70%;
    border: 0;
    padding-left: 0;
    margin-left: 0;
  }

  .grid.grid-cols-6.gap-4 .col-start-1.col-end-5 {
    grid-column-end: 8;
  }

  .grid.grid-cols-6.gap-4 .col-start-1.col-end-3 {
    grid-column-end: 8;
  }

  .bg-white.calender-box.box-radious.mb-4 .md\:grid.md\:grid-cols-2.md\:divide-x.md\:divide-gray-200 {
    grid-template-columns: inherit;
  }

  .bg-white.calender-box.box-radious.mb-4 .md\:pr-14.pl-20 {
    padding: 0;
    padding-bottom: 40px;
  }

  section.mt-12.md\:mt-0.calender-time-box.pl-10 {
    border: 0 !important;
    padding: 0;
  }

  img.absolute.inset-0.h-full.w-full.object-cover {
    object-fit: contain;
  }

  .h-\[calc\(100vh_-_306px\)\].flex.bg-dl-screen-gray {
    height: auto;
  }
}
@media (max-width: 991px) {
  button#headlessui-menu-button-3 {
    font-size: 13px;
  }
  .docter-just-click figure img {
    width: 330px;
  }
  .docter-just-click figcaption h3 {
    line-height: 35px;
    font-size: 30px;
  }
  .featured-physicians-inner {
    text-align: center;
    width: 47%;
  }
  .btn-md {
    height: 48px;
    width: 170px;
    font-size: 18px;
  }
  .featured-physicians-inner figcaption h3 {
    font-size: 18px;
  }
  h2.slider-title {
    font-size: 17px;
    margin-bottom: 0;
  }
  .slider-left {
    border-radius: 16px;
  }
  .our-plate-forms.pt-20 {
    padding-top: 46px;
  }
  .our-plate-forms-L {
    padding: 0 165px;
    width: 25%;
  }
  .our-plate-forms-L figcaption h3 {
    font-size: 30px;
    line-height: 1.1;
  }
  .our-plate-forms .grid.grid-cols-2.h-full {
    grid-template-columns: max-content;
    display: flex;
  }
  .our-plate-forms-R {
    flex: 1;
  }
  span.medi-bottal img {
    width: 85px;
  }
  h2.common-title {
    font-size: 20px;
  }
  h1.docters-team-title {
    font-size: 29px;
    line-height: 36px;
  }
  .docters-team-main-R {
    display: none;
  }
  .docters-team-main-L {
    width: 100%;
    margin-bottom: -110px;
  }
  .docters-team-main-L-title-content.pb-8.max-w-md {
    max-width: 100%;
  }
  .docters-team-main-L-title-content h1 br {
    display: none;
  }
  h1.docters-team-title {
    font-size: 28px;
  }
  .docter-just-click figcaption {
    width: 50%;
    padding: 30px 35px 0 55px;
  }
  .docter-link-title {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .flex.items-center.md\:ml-12 {
    margin-left: 10px;
  }
  a.text-base.font-medium.text-gray-500.hover\:text-dl-accent {
    margin-left: 10px !important;
  }
  button#headlessui-menu-button-3 {
    font-size: 13px;
    margin-left: 10px;
  }
  a.text-base.font-medium.text-gray-500.hover\:text-dl-accent {
    margin-left: 10px !important;
    font-size: 13px;
  }
  button#headlessui-menu-button-2 {
    font-size: 14px;
  }
  span.shape-one img {
    width: 44px;
  }
  span.shape-two {
    width: 100px;
  }
  .our-plates-form-inner {
    height: 220px;
  }
  h1.docters-team-title {
    font-size: 28px;
    margin-bottom: 5px;
  }
  .team-docter-title {
    min-height: 40px !important;
  }
  .form-field-box {
    height: 95px;
  }
  .submit-btn {
    height: 95px;
  }

  .grid.grid-cols-2.h-full {
    grid-template-columns: 100%;
  }
  .what-is-docter-link-box-L {
    order: 2;
  }
  .what-is-docter-link-box-inner {
    border-top-right-radius: 120px;
    border-bottom-right-radius: 40px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    margin-top: 100px;
  }
  figure.img-box img {
    width: 75%;
    margin: auto;
  }
  .whta-is-docter-link-bottom {
    padding-top: 0;
  }
  .what-is-our-goal {
    width: 95%;
  }
  .shape-4 {
    bottom: -36px;
  }
  .wrapper-box.who-we-are-four-box-wrapper {
    width: 100%;
  }
  .who-we-are-four-box-inner h5 {
    font-size: 18px;
    line-height: 1.5;
  }
  .bg-white.rounded-lg.px-20.pt-12.pb-10 {
    width: 90%;
    margin: auto;
  }
  main.pt-8.pb-16.min-h-\[calc\(100vh_-_306px\)\] {
    padding-bottom: 30px;
  }
  .min-w-0.flex-1.px-10.py-7.docter-profile-R.flex {
    width: 100%;
    flex-wrap: wrap;
  }
  .pr-5.flex-1 {
    padding-right: 0;
    margin-bottom: 20px;
  }

  button.custome-bg.inline-flex {
    padding: 10px 13px;
    font-size: 14px;
    margin-top: 15px;
  }
  .appointment.bg-sky-50.rounded-xl.py-4.px-7.flex.items-center.mr-8 {
    padding: 15px 20px;
  }

  .bullet-list-box li {
    font-size: 14px;
  }

  .px-4.py-4.sm\:px-6.border-b.divide-slate-200.border-black.flex.items-center figure {
    margin-right: 10px;
  }

  .relative.max-w-3xl.mx-auto {
    max-width: 90%;
  }

  .bg-white.rounded-lg.px-40.pt-12.pb-10.border-r-40 {
    padding: 80px 80px;
  }

  .space-y-6.sm\:px-6.lg\:px-0.lg\:col-span-10.side-bar-right.bg-dl-screen-lightblue {
    width: 65%;
  }

  aside.lg\:col-span-2.bg-white.side-bar-profile {
    width: 35%;
  }
  hr.border-black.w-full.border-tabs .grid.grid-cols-6.gap-4 {
    grid-template-columns: inherit;
  }
  .md\:grid.md\:grid-cols-2 {
    grid-template-columns: inherit;
  }
  section.mt-12.md\:mt-0.md\:pl-14.border-l.border-black.ml-8 {
    border: 0;
    padding: 0;
    margin: 0;
    margin-top: 20px;
  }

  .calender-show-date.mb-20 {
    margin-bottom: 30px;
  }

  .boxfilter-list-comm {
    position: relative;
    padding: 0 30px;
    flex: 1;
  }
  span.availability-date {
    font-size: 16px;
  }

  .availability-box.light-blue {
    padding: 15px 25px;
  }

  .filters-list-show-box-inner .btn-box {
    text-align: center;
    margin: auto;
    margin-top: 30px;
  }

  .filters-list-show-box-inner .btn-box {
    margin-top: 30px;
  }
  .py-8.px-8 .pt-16.mb-20 {
    padding-top: 0;
  }
  .max-w-full.mx-auto.px-20 {
    padding: 0 30px;
  }

  ul.filters-box-list {
    flex-wrap: wrap;
  }
  ul.filters-box-list li {
    margin-bottom: 15px;
  }
  ul.filters-box-list li {
    margin-bottom: 5px;
    margin-right: 5px;
    width: 32%;
  }

  .create-appoinment .md\:pr-14.pl-20 {
    padding-left: 0;
    padding: 0;
  }

  .create-appoinment section.mt-12.md\:mt-0.calender-time-box.pl-10 {
    padding: 0;
    margin-top: 35px;
    border-left: 0 !important;
  }

  .flex.items-center.justify-center .green-info-box {
    width: 100%;
  }

  .information-box-info .name-docter-info-box-C {
    width: 100%;
  }
  .w-1\/5.flex.flex-col.gap-2.items-center {
    width: 100%;
    display: flex !important;
    flex-direction: revert;
    align-items: center;
    margin-top: 20px;
  }
  .w-1\/5.flex.flex-col.gap-2.items-center button {
    margin-bottom: 0;
  }

  .information-box-info.common-box-white {
    flex-wrap: wrap;
    border-radius: 24px;
  }

  ul.time-slot-name-text li {
    width: 50%;
  }

  .lg\:grid.lg\:grid-cols-12.gap-4 .bg-white.calender-box.box-radious.mb-4 {
    padding: 30px 30px;
  }

  img.w-72.mx-auto {
    width: 170px;
  }

  .flex.flex-col.gap-4.w-1\/2.pb-8.px-4 {
    width: 100%;
  }

  .grid.grid-cols-6.gap-6.gap-x-32 {
    grid-template-columns: initial;
    width: 100%;
    display: inherit;
  }

  .col-start-1.col-end-4 {
    width: 100%;
    grid-column-end: initial;
    grid-column-start: initial;
  }
}
@media (max-width: 767px) {
  .field-2.form-field-box,
  .field-3.form-field-box,
  .field-4.form-field-box {
    width: 100px;
  }
  button.submit-btn img {
    width: 20px;
    height: 20px;
  }
  .submit-btn {
    width: 200px;
  }
  .form-field-box label {
    font-size: 14px;
    margin-bottom: 0;
    line-height: normal;
  }
  .form-field-box {
    width: 200px;
  }
  .field-2.form-field-box,
  .field-3.form-field-box,
  .field-4.form-field-box {
    padding-left: 54px;
    width: 165px;
    padding-right: 0 !important;
  }
  .form-field-box-main .submit-btn {
    width: 160px;
    font-size: 14px;
  }
  .form-field-box .shadow-sm,
  .form-field-box input {
    font-size: 14px;
  }
  .docter-just-click figcaption {
    width: 100%;
    padding: 20px 200px 25px 45px;
  }
  .docter-just-click figure {
    margin-top: 0;
    padding-right: 55px;
    position: absolute;
    right: 0;
    width: 190px;
    bottom: 0;
  }
  .featured-physicians-inner {
    text-align: center;
    width: 46%;
  }
  .btn-md {
    height: 43px;
    width: 170px;
    font-size: 14px;
  }
  .featured-physicians-inner figcaption h3 {
    font-size: 16px;
  }
  .slider-inner {
    flex-direction: column;
  }
  .slider-left {
    border-radius: 16px;
    order: 2;
  }
  .slider-left {
    width: 100%;
    padding: 18px 60px;
  }
  .slider-right {
    width: 53%;
    position: relative;
    margin-left: auto;
  }
  .our-plate-forms-L {
    padding: 0;
    width: 50%;
  }
  .our-plate-forms-L figcaption h3 {
    font-size: 20px;
    padding: 0 30px;
  }
  .btn-md {
    height: 43px;
    width: 130px;
    font-size: 14px;
  }
  span.medi-bottal img {
    width: 65px;
  }
  .our-plates-form-inner {
    height: 200px;
  }
  .chat-info svg {
    width: 40px;
  }
  .chat-info {
    width: 80px;
    height: 80px;
  }
  .mx-auto.md\:grid.md\:grid-cols-3 {
    display: flex;
  }
  .team-docter-title {
    min-height: 65px !important;
    font-size: 16px;
  }
  .docter-just-click figcaption h3 {
    font-size: 20px;
  }
  .pt-12.lg\:px-8.mx-auto.px-4.relative.sm\:px-6 {
    padding-top: 15px !important;
  }

  .what-is-docter-link-box-L {
    padding: 20px 35px 45px;
  }
  .shape-5 {
    position: absolute;
    right: 20px;
    bottom: 215px;
  }
  .what-is-our-goal {
    padding: 35px 150px 90px 60px;
  }
  .whta-is-docter-link-box {
    margin-bottom: 100px;
  }
  .what-is-our-goal {
    padding: 35px 150px 60px 60px;
  }

  .employee-info-dt {
    font-size: 16px;
  }

  dd.employee-info-dd {
    font-size: 20px;
  }

  .who-we-are-four-box-inner:after {
    display: none;
  }
  .who-we-are-four-box-inner h5 {
    font-size: 16px;
    text-align: center;
    width: 100%;
  }

  .who-we-are-four-box-inner {
    min-height: 85px;
  }
  .who-we-are-four-box.pt-20.pb-60 {
    padding-top: 0;
    padding-bottom: 75px;
  }
  .who-we-are-four-box-inner {
    padding: 20px 20px;
  }

  .bg-white.rounded-lg.px-40.pt-12.pb-10.border-r-40 {
    padding: 50px 40px;
  }

  .lg\:grid-cols-12 {
    grid-template-columns: 100% !important;
    display: flex !important;
    flex-direction: column;
  }

  .btn-sidebar.flex.flex-col.gap-2.w-9\/12.my-\[50px\].mx-auto {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .space-y-6.sm\:px-6.lg\:px-0.lg\:col-span-10.side-bar-right.bg-dl-screen-lightblue {
    width: 100%;
  }

  aside.lg\:col-span-2.bg-white.side-bar-profile {
    width: 100%;
    padding: 20px 27px;
    background: transparent;
  }
  .btn-sidebar.flex.flex-col.gap-2.w-9\/12.my-\[50px\].mx-auto {
    width: 100%;
    max-width: 100%;
    margin: 20px 0;
    flex-wrap: nowrap;
    overflow: auto;
    white-space: nowrap;
    padding-bottom: 15px;
  }

  .btn-sidebar button {
    padding: 0 16px;
  }

  .profile-top-content {
    margin-bottom: 30px;
  }

  .flex.flex-col.gap-2.w-1\/2 {
    width: 100%;
  }

  .md\:pr-14.pl-20 {
    padding-left: 0;
  }

  .boxfilter-list-comm h4 {
    font-size: 14px;
  }

  .boxfilter-list-comm {
    width: 50%;
  }
  .availability-box h5 {
    font-size: 16px;
  }

  span.availability-date {
    font-size: 14px;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 34px !important;
  }

  .rounded-2xl.overflow-hidden.mt-0.mb-45 .col-span-3.sm\:col-span-2 {
    grid-column: span 8;
  }
  .grid.grid-cols-6 .col-span-4.sm\:col-span-2 {
    grid-column: span 8;
  }

  .grid.grid-cols-6 .col-span-6 {
    grid-column: span 8;
  }
  .bg-dl-screen-lightblue.p-20 {
    padding: 30px;
  }
  .flex.items-center.max-w-full.bg-white.border-l-8.border-sky-500.py-8.px-8.rounded-l-md.rounded-r-full {
    flex-direction: column;
  }
  .flex-1.pr-10.mr-10.border-r.border-black {
    width: 95%;
    padding-right: 0;
    border: 0;
    margin: 0;
  }
  .flex.items-center.max-w-full.bg-white.border-l-8.border-sky-500.py-8.px-8.rounded-l-md.rounded-r-full {
    flex-direction: column;
    border-radius: 12px;
  }
  button.py-3.px-4.bg-sky-400.rounded-full.text-white.font-medium.text-md {
    font-size: 14px;
  }
  .appointment.bg-sky-50.rounded-xl.py-4.px-7.flex.items-center.mr-8 {
    padding: 15px 20px;
    width: 100%;
  }
  .min-w-0.flex-1.px-10.py-8.md\:grid.md\:grid-cols-2.md\:gap-4.docter-profile-R {
    padding: 20px 10px;
  }

  .pt-8.px-4.sm\:px-0 {
    padding: 0;
  }

  .space-y-6.sm\:px-6.lg\:px-0.lg\:col-span-10.side-bar-right.bg-dl-screen-lightblue {
    padding-top: 20px !important;
  }

  .flex.information-box-info.common-box-white.mt-20 {
    margin-top: 40px;
  }

  .create-appoinment .bg-white.calender-box {
    padding: 45px 45px;
  }

  .w-1\/5.flex.flex-col.gap-2.items-center button {
    margin-bottom: 0;
    font-size: 14px;
  }

  .docter-profile-R .flex {
    justify-content: center;
  }
}

@media (max-width: 575px) {
  .form-field-box-main {
    flex-wrap: wrap;
  }
  .field-2.form-field-box,
  .field-3.form-field-box,
  .field-4.form-field-box {
    width: 100%;
  }
  .form-field-box {
    width: 100%;
  }
  .field-2.form-field-box,
  .field-3.form-field-box,
  .field-4.form-field-box {
    padding-left: 25px;
    width: 100%;
    padding-right: 0 !important;
  }
  .form-field-box {
    width: 100%;
  }
  .submit-btn {
    margin-left: 0;
  }
  .featured-physicians-inner {
    text-align: center;
    width: 70%;
    margin: auto;
  }
  .grid.grid-cols-12.gap-12 {
    grid-template-columns: initial;
    gap: 0;
  }
  .field-2.form-field-box,
  .field-3.form-field-box,
  .field-4.form-field-box {
    margin-left: 0;
  }
  .field-2.form-field-box,
  .field-3.form-field-box,
  .field-4.form-field-box {
    margin-left: 0;
    border-radius: 68px;
  }
  .form-field-box {
    height: 70px;
    margin-bottom: 12px;
  }
  .search-btn-box {
    width: 100%;
  }
  .form-field-box-main .submit-btn {
    width: 100%;
    font-size: 14px;
    border-radius: 68px;
    height: 70px;
    padding-left: 0;
  }
  .form-field-box .shadow-sm,
  .form-field-box input {
    font-size: 14px;
    line-height: normal;
    height: auto !important;
  }
  .field-3 .react-datepicker-popper {
    left: 0 !important;
  }
  .our-plate-forms .grid.grid-cols-2.h-full {
    grid-template-columns: max-content;
    display: flex;
    flex-wrap: wrap;
  }
  .our-plate-forms-L {
    padding: 0;
    width: 100%;
    border-top-left-radius: 130px;
    border-top-right-radius: 130px;
    padding-top: 20px;
  }
  span.medi-bottal {
    top: -38px;
    bottom: auto;
  }
  .our-plates-form-inner {
    height: 200px;
    overflow: visible;
  }
  section.bg-dl-screen-lightblue .py-10.px-4.sm\:px-6.md\:flex.md\:flex-col.md\:py-14.md\:px-5.lg\:px-8 {
    padding-bottom: 15px;
  }
  .docters-team-title-sub p {
    font-size: 18px;
  }
  .form-field-box {
    height: 65px;
    margin-bottom: 12px;
    padding-bottom: 29px;
  }
  .form-field-box-main .submit-btn {
    height: 60px;
  }
  #list-cities {
    margin-top: 4.5rem;
    width: 93%;
  }
  #arrow-icon {
    margin-right: 1rem;
  }
  .doc-link-box {
    border-radius: 48px;
    width: 100%;
  }
  .docters-team-main-L .lg\:gap-y-0.gap-12 {
    flex-wrap: wrap;
  }
  .our-plate-forms.pt-20 {
    padding-top: 65px;
  }
  .chat-info {
    right: 20px;
  }

  .what-is-our-goal {
    padding: 50px 10px 60px 60px;
  }
  .what-is-docter-link-box-R {
    padding-bottom: 90px;
  }
  .whta-is-docter-link-box-main {
    padding-top: 0;
    padding-bottom: 70px;
  }
  .docter-team-sub-title {
    font-size: 14px;
  }
  .what-is-our-goal {
    padding: 20px 35px 45px;
  }
  .shape-5 {
    position: absolute;
    right: 20px;
    bottom: 155px;
  }
  figure.shape-5 img {
    width: 75px;
  }
  .whta-is-docter-link-box {
    margin-bottom: 45px;
  }
  h2.docter-team-title {
    font-size: 22px;
    margin-bottom: 4px;
  }
  .what-is-docter-link-box-inner {
    margin-top: 80px;
  }

  .who-we-are-sub-title {
    font-size: 14px;
    line-height: 1.8;
  }
  .footer-text-who {
    line-height: 26px;
  }
  .who-we-are-four-box.pt-20.pb-60 {
    padding-top: 0;
    padding-bottom: 55px;
  }
  .relative.who-we-are-L .relative.pt-64.pb-10.bg-blue-gredient {
    padding-top: 200px;
  }
  .relative.max-w-3xl.mx-auto .bg-white.rounded-lg.px-20.pt-12.pb-10 {
    padding: 30px 30px;
  }
  .min-w-0.flex-1.flex {
    flex-wrap: wrap;
  }
  .docter-profile-R.flex {
    width: 100%;
    flex-wrap: wrap;
    flex: initial;
  }

  p.color-custom.font-bold.text-3xl.text-center {
    font-size: 28px;
  }
  .flex.justify-center.mb-12 {
    flex-wrap: wrap;
  }
  .flex.justify-center.mb-12 a {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }

  .tabs-heading-title .grid.grid-cols-6.gap-6,
  .grid.grid-cols-6.gap-4 {
    grid-template-columns: initial;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .col-start-1.col-end-4 {
    width: 100%;
  }

  .col-start-4.col-end-7 {
    width: 100%;
  }

  .col-span-6.sm\:col-span-3 {
    width: 100%;
  }

  .col-span-6 {
    width: 100%;
  }

  .col-start-1.col-end-5 {
    width: 100%;
  }

  .rounded-full.border.border-gray-100.bg-white.p-3.shadow-md.w-1\/3 {
    width: 85%;
  }

  .bg-white.calender-box {
    padding: 30px 20px;
  }

  .boxfilter-list-comm h4 {
    font-size: 14px;
    text-align: left;
  }

  .boxfilter-list-comm {
    width: 100%;
  }

  .boxfilter-list-comm {
    width: 100%;
    margin-bottom: 23px;
    padding: 0;
    flex: initial;
  }
  .boxfilter-list-comm:after {
    border: 0;
  }

  .filters-list-show-box-inner .btn-box {
    text-align: center;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .filters-info.flex.items-center.boxfilter-list-comm:first-child {
    /* flex-direction: column; */
  }

  figcaption.ml-10.flex-1 {
    margin-left: 0;
    text-align: center;
    margin-top: 10px;
  }

  .boxfilter-list-comm h4 {
    font-size: 14px;
    text-align: center;
  }

  .field-3.form-field-box {
    z-index: auto;
  }
  .react-datepicker-popper {
    z-index: 99 !important;
  }

  hr.border-0.border-b-4.border-blue.rounded-full {
    margin-top: 10px;
  }
  .search-box-top-main {
    margin-bottom: 20px;
  }
  .flex.items-center.max-w-full.bg-white.border-l-8.border-sky-500.py-8.px-8.rounded-l-md.rounded-r-full {
    flex-direction: column;
    border-radius: 12px;
    padding: 25px 15px;
  }
  .calender-show-date figcaption h4 {
    font-size: 18px;
  }
  .calender-show-date figcaption h2 {
    font-size: 18px;
  }
  textarea#educationDetail {
    font-size: 14px;
  }

  .max-w-full.mx-auto.px-20.appoinment-calender-box {
    padding: 0 10px;
  }

  .py-7 .flex.items-center.gap-20.mt-3 {
    gap: 10px;
  }
  dl.content-list-box.flex.items-center.justify-between.px-6.py-4 {
    flex-wrap: wrap;
  }
  button.py-3.px-4.bg-sky-400.rounded-full.text-white.font-medium.text-md {
    margin-top: 20px;
    height: 36px;
    display: flex;
    align-items: center;
  }
  .docter-profile-L {
    text-align: center;
    margin: auto;
    margin-top: -80px;
  }
  .min-w-0.flex-1.px-10.py-7.docter-profile-R.flex {
    width: 100%;
    flex-wrap: wrap;
    padding: 20px 20px;
    text-align: center;
    justify-content: center;
  }
  span.flex.items-center.text-sm.mr-5.py-2.px-8.text-black.docter-tag.bg-sky-50.rounded-full.ml-20.mt-0 {
    margin-left: 26px;
    margin-right: 0;
  }

  p.mt-2.flex.items-center.text-sm.mr-5.py-3.px-8.text-black.docter-tag.bg-sky-50.rounded-full {
    margin: auto;
    margin-bottom: 10px;
  }

  .appointment.bg-sky-50.rounded-xl.py-4.px-7.flex.items-center.mr-8 {
    padding: 15px 20px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  .min-w-0.flex-1.px-10.py-7.docter-profile-R.flex .pr-5.flex-1 {
    width: 100%;
    flex: inherit;
    margin-bottom: 0;
  }
  .min-w-0.flex-1.px-10.py-8.md\:grid.md\:grid-cols-2.md\:gap-4.docter-profile-R {
    width: 100%;
    flex: initial;
    text-align: center;
    justify-content: center;
    padding: 25px 10px;
  }
  .flex.docter-profile-R-inner {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
  }
  p.mt-2.flex.items-center.text-sm.mr-5.py-3.px-8.text-black.docter-tag.bg-cyan-50.rounded-full {
    margin-right: 0;
    margin: 0 5px;
  }

  ul.filters-box-list li {
    margin-bottom: 5px;
    margin-right: 5px;
    width: 48%;
  }

  main.max-w-7xl.mx-auto.px-4.sm\:px-6.lg\:px-8 .bg-dl-screen-lightblue.lg\:px-8.mx-auto.px-4.relative.sm\:px-6 {
    padding: 0;
  }
  .relative.z-10.flex.items-baseline.justify-center.pt-12.pb-6.border-gray-200 {
    padding-top: 0;
  }

  span.tag-info.light-blue.py-2.px-3.rounded-full.block.text-center.text-base.mt-3.w-44 {
    margin: auto;
  }

  .col-start-1.col-end-7.form-label-box {
    grid-column-end: initial;
    width: 100%;
  }

  .information-box-info-L {
    flex: 1 1;
    padding-right: 0;
  }

  .create-appoinment .information-box-info-L .grid.grid-cols-2.gap-4.mb-8 {
    grid-template-columns: auto;
  }

  .create-appoinment .flex.information-box-info.common-box-white.mt-20 {
    margin-top: 40px;
    padding: 35px 35px;
  }

  .create-appoinment .flex.information-box-info.common-box-white.mt-20 {
    margin-top: 40px;
    padding: 35px 35px;
    border-radius: 24px;
  }

  .calender-show-date.flex-auto.mb-12 {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .calender-show-date .ml-auto {
    margin-left: inherit;
    margin-top: 20px;
  }

  .create-appoinment .bg-white.calender-box {
    padding: 45px 25px;
  }

  .py-1\.5 {
    font-size: 17px;
  }

  .flex.items-center.calender-top-head {
    padding: 0 15px;
  }

  .grid.grid-cols-6.gap-4 .col-start-1 {
    grid-column-end: initial;
    width: 100%;
  }

  .py-6.px-6.space-y-7.sm\:p-5.tabs-heading-title .grid.grid-cols-2.gap-4 {
    grid-template-columns: inherit;
  }

  h2.mt-6.text-3xl.font-extrabold.text-dl-secondary {
    font-size: 26px;
  }

  .featured-physicians-inner figure,
  .direction-info-L figure {
    max-width: 45px;
    max-height: 45px;
    margin-bottom: 10px;
  }
  .featured-physicians-inner figcaption h3 {
    height: auto;
    font-size: 14px;
  }
  .docter-info {
    margin: 15px auto;
    padding: 5px 28px;
    font-size: 14px;
  }
  .bottom-link {
    margin-top: 15px;
    text-align: center;
  }

  .featured-physicians-inner .btn-md {
    height: 36px;
  }
  .featured-physicians-inner {
    padding: 15px;
  }
  .mis-doctores-list.doctor-list .doctor-card {
    width: 100%;
  }
  .doctor-list .doctor-card-2 {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .docter-just-click figcaption {
    width: 100%;
    padding: 20px 45px 93px 45px;
  }
  .docter-just-click figcaption h3 {
    font-size: 25px;
  }
  .featured-physicians-inner {
    width: 100%;
  }
  .slider-right {
    padding-top: 60px;
  }
  .our-plate-forms-L {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
  .our-plates-form-inner {
    border-radius: 30px;
  }
  ul.grid.grid-cols-1.gap-5.sm\:gap-6.sm\:grid-cols-2.lg\:grid-cols-4 {
    gap: 10px;
  }
  .px-5.py-2.link-footer {
    padding: 0 15px;
  }
  .all-reserved {
    font-size: 14px;
  }

  .docter-link-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  h2.docter-team-title {
    font-size: 22px;
    margin-bottom: 4px;
    line-height: 1.5;
  }
  .what-is-our-goal {
    width: 92%;
  }
  .headlessui-popover-panel-4 .grid.grid-cols-2.gap-4 {
    grid-template-columns: 100%;
  }
  .headlessui-popover-panel-4 .grid.grid-cols-2.gap-4 a {
    width: 100%;
  }
  h2.docter-team-title {
    font-size: 18px;
  }

  .wrapper-box.who-we-are-four-box-wrapper .grid.grid-cols-2.gap-x-4.gap-y-8 {
    grid-template-columns: 100%;
    gap: 10px;
  }
  .who-we-are-four-box-inner {
    min-height: auto;
  }
  .who-we-are-R .pb-8 {
    padding-bottom: 15px;
  }

  .docter-profile-inner {
    padding: 0 15px;
  }

  .grid.grid-cols-2.gap-4 {
    grid-template-columns: 100%;
  }

  p.color-custom.font-bold.text-3xl.text-center {
    font-size: 26px;
  }

  .col-span-1.\.\.\..border-r.border-black.box-l {
    border: 0;
    border-bottom: 1px solid #000;
    padding-bottom: 30px;
  }

  aside.lg\:col-span-2.bg-white.side-bar-profile {
    width: 100%;
    padding: 0 15px;
  }

  .btn-sidebar.flex.flex-col.gap-2.w-9\/12.my-\[50px\].mx-auto button {
    font-size: 13px;
  }

  .space-y-6.sm\:px-6.lg\:px-0.lg\:col-span-10.side-bar-right.bg-dl-screen-lightblue {
    width: 100%;
    padding: 20px !important;
  }

  ul.absolute.z-10.mt-1.max-h-60.w-full.overflow-auto {
    width: 100%;
    max-width: 200px !important;
  }

  .grid.grid-cols-6.gap-6.pt-\[20px\].px-\[40px\].pb-\[30px\] {
    padding: 20px;
  }

  .filters-list-show-box-inner {
    border-radius: 10px;
  }

  .filters-list-show-box-inner .btn-box {
    padding: 0 10px;
  }

  h1.text-4xl.font-extrabold.tracking-tight.text-dl-secondary {
    font-size: 26px;
    line-height: 1.5;
  }
  .bg-dl-screen-lightblue.lg\:px-8.mx-auto.px-4.relative.sm\:px-6 {
    padding: 15px;
  }
  hr.border-0.border-b-4.border-blue.rounded-full {
    margin-top: 10px;
  }

  .max-w-full.mx-auto.px-20.appoinment-calender-box {
    padding: 0;
  }
  .flex-1.pr-10.mr-10.border-r.border-black {
    margin-bottom: 20px;
  }

  ul.filters-box-list li {
    margin-bottom: 5px;
    margin-right: 5px;
    width: 100%;
  }

  .flex.justify-center.mt-20 {
    margin-top: 30px;
  }

  .no-time-show {
    font-size: 16px;
  }

  .week-name-list div {
    font-size: 14px;
  }

  .green-info-box h5 {
    font-size: 16px;
  }

  .green-info-box p {
    font-size: 16px;
  }

  .box-l {
    padding-right: 0;
  }

  .text-center.bg-green-100.border-green-500.border-l-8.py-5.rounded-lg.mb-10 p.text-green-600.font-bold.text-lg {
    font-size: 14px;
  }

  .grid.grid-cols-2.gap-4 .col-span-1.\.\.\..border-r.border-black.pr-5 {
    border: 0;
    border-bottom: 1px solid #000;
    padding-bottom: 30px;
    padding-right: 0;
  }

  .grid.grid-cols-2.gap-4 .mt-2.flex.items-start.justify-start.pl-5 {
    justify-content: center;
    padding: 0;
  }

  .grid.grid-cols-6.gap-6.mb-4 {
    grid-template-columns: initial;
    display: initial;
  }

  .grid.grid-cols-6.gap-6.mb-4 .col-start-1,
  .grid.grid-cols-6.gap-6.mb-4 .col-start-4 {
    margin-bottom: 15px;
  }

  .md\:grid.md\:grid-cols-2.md\:divide-x.md\:divide-gray-200 section.mt-12.md\:mt-0.calender-time-box.pl-10 {
    margin-top: 0;
  }

  .text-center.w-2\/3.bg-green-100.border-green-500.border-l-8.py-5.rounded-lg.mb-10 {
    margin-top: 25px;
    width: 100%;
  }

  h2.mt-6.text-3xl.font-extrabold.text-dl-secondary {
    font-size: 24px;
    line-height: 1.3;
  }

  .flex-1.flex.flex-col.justify-center.py-12.px-4.sm\:px-6.lg\:flex-none.lg\:px-20.xl\:px-24 {
    padding: 35px 30px;
  }
}

.mis-doctores-list .grid {
  width: 100%;
}
.mis-doctores-list.doctor-list .doctor-card {
  background: #fff;
}


/* appointment slots */
.time-slot-selector {
  background-color: #eaf1f8;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  font-family: Arial, sans-serif;
}

.date-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.date-box {
  background-color: #005cb9;
  color: white;
  padding: 5px 10px;
  border-radius: 8px;
  font-weight: bold;
  margin-right: 10px;
}

.available-times-text {
  color: #333;
}

h4 {
  color: #555;
  margin: 0 0 15px;
}

.time-slots {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  max-height: none;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.time-slots.scrollable {
  max-height: 200px; /* Set the max height for scroll */
  overflow-y: auto;
}

.time-slot {
  padding: 10px;
  border-radius: 25px;
  border: none;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.time-slot.selected {
  background-color: #005cb9;
  color: white;
  font-weight: bold;
}

.time-slot:hover {
  background-color: #b3c8e8;
}

.show-all-button {
  margin-top: 15px;
  padding: 0;
  background: none;
  border: none;
  color: #007bff;
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
}

.show-all-button:hover {
  color: #0056b3;
  text-decoration: underline;
}

.select-time-label {
  text-align: left;
}

/* appointment slots */